
import { Options, Vue } from 'vue-class-component';
import { GameClient } from '@/services/Services';
import { StorageServices } from '@/services/StorageServices';
import InfoButton from '../components/infoButton.vue';
import * as OM from '@/Model';

@Options({
    components: {
        InfoButton
    }
})
export default class Ranking extends Vue {
    
    ranking: OM.UserExtractionResumeVM[] = [];
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();

    created() {
        this.loggedUser = StorageServices.getLoggedUser();

        Promise.all([
            GameClient.ranking()
        ])
        .then(xs => {
            this.ranking = xs[0];
        })
    }

    getSurnameTruncate(surname: string) {
        if(!surname)
            return;
            
        return surname.substring(0, 1) + ".";
    }

    getTimerValue(seconds: number) {
        var totalMilliseconds = seconds * 1000;
        var totalTimeInSeconds = Math.round(totalMilliseconds / 1000);
        var totalMinutes = Math.floor(totalTimeInSeconds / 60);
        var seconds = totalTimeInSeconds % 60;
        var hours = Math.floor(totalMinutes / 60);
        var minutes = totalMinutes % 60;

        var finalHours = this.returnData(hours);
        var finalMinutes = this.returnData(minutes);
        var finalSeconds = this.returnData(seconds);

        return finalHours + ":" + finalMinutes + ":" + finalSeconds;
    }

    returnData(input) {
        return input >= 10 ? input : '0' + input;
    }

    checkIfIsMe(userIdentifier: string) {
        return this.loggedUser.identifier == userIdentifier;
    }

    get myPosition() {
        var myGame = this.ranking.find(x => x.userIdentifier == this.loggedUser.identifier);
        var myPosition = this.ranking.indexOf(myGame) + 1;

        return myPosition;
    }

    goToMyPosition() {
        var element = document.getElementById(this.loggedUser.identifier);
        element.scrollIntoView();
    }

}
